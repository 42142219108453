import type { Dispatch, SetStateAction } from 'react';
import { useCookies } from '@packages/utilities';
import type { Promotion } from '../types';

export const useSetActiveCode = (
  selectedData: Promotion | undefined,
  shop: Promotion | undefined,
  locale: string,
  setActiveData: Dispatch<SetStateAction<Promotion | undefined>>,
  setShowAccordionOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedQueryData: Dispatch<SetStateAction<Promotion | undefined>>,
  setSelectedRemoteData: (
    args_0: Promotion | ((prev: Promotion | null) => Promotion | null) | null,
  ) => void,
  setIsActive: Dispatch<SetStateAction<boolean>>,
) => {
  const { destroyCookie, setCookie } = useCookies();
  if (typeof window === 'undefined') {
    return async () =>
      Promise.reject(new Error('useSetActiveCode must only be called clientside.'));
  }
  return async (
    cartCodeToActivate?: string | null,
    textsCodeToActivate?: string | null,
    isPaybackPromotion: Promotion['isPaybackPromotion'] = false,
  ) => {
    const { apiKeyManager } = await import('@empiriecom/mybuy-session');
    // eslint-disable-next-line no-useless-catch
    try {
      // NJS-3302 await the user session - a new session cleanes up local storage and would corrupt local storage if this runs after setBonuscodes
      await apiKeyManager.getApiKey();
    } catch (error) {
      throw error;
    }

    // NJS-1214 - cleanup all bonuscodes by override before adding the new one
    const currentCodes = [...apiKeyManager.getBonuscodes(), ...apiKeyManager.getLoyaltyCodes()];
    if (currentCodes.length > 0) {
      await apiKeyManager.removeBonuscodes(currentCodes);
      await apiKeyManager.removeLoyaltyCodes(currentCodes);
      // remove bonuscode cookie here - the apikeymanager not yet does it - may be removed when NJS-1249 is resolved
      destroyCookie(null, 'bonuscode', { path: '/' });
    }

    if (cartCodeToActivate) {
      // ORDER-2508 use setLoyaltyCodes instead of setBonusCodes if it is a payback promotion
      if (isPaybackPromotion) {
        await apiKeyManager.setLoyaltyCodes([cartCodeToActivate], true);
      } else {
        await apiKeyManager.setBonuscodes([cartCodeToActivate], true);
      }
    }

    destroyCookie(null, 'textsBonusCode', { path: '/' });
    if (textsCodeToActivate) {
      setCookie(null, 'textsBonusCode', textsCodeToActivate, { path: '/' });
    }
    // INSPIRE-3358 - either selectedData oder shopData may be redeemed - it depends on the code to activate
    const dataToRedeem = [selectedData, shop].find(
      // INSPIRE-3409 - explicitly check on promotionData?.cartCode to not wrongly
      // set a linked promotion as active where cartCode is null everytime
      (promotionData) => promotionData?.cartCode && promotionData.cartCode === cartCodeToActivate,
    );
    if (dataToRedeem?.pro?.[0]) {
      const { checkoutManager } = await import('@empiriecom/mybuy-checkout');
      checkoutManager
        .addToBasket(dataToRedeem.pro[0], 1, false, {}, [], {}, locale)
        .catch(() => {});
    }
    if (dataToRedeem) {
      setActiveData(dataToRedeem);
      setShowAccordionOpen(true);
    } else {
      setShowAccordionOpen(false);
    }
    setSelectedQueryData(undefined);
    setSelectedRemoteData(null);
    if (cartCodeToActivate) {
      setIsActive(true);
    }
  };
};
