import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Fade, Chip, Box } from '@packages/shared';
import { ArrowMiniUp32 } from '@packages/themes/icons';
import { useIntl } from 'react-intl';

export type ScrollTopButtonProps = {};

export const ScrollTopButton: FC<ScrollTopButtonProps> = () => {
  const [show, setShow] = useState(false);
  const { formatMessage } = useIntl();
  useEffect(() => {
    const handleScroll = () => {
      setShow(window.pageYOffset > window.innerHeight);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Fade in={show}>
      <Box>
        <Chip
          sx={{
            '&:hover': {
              backgroundColor: 'grey.light',
            },
          }}
          icon={<ArrowMiniUp32 />}
          onClick={scrollToTop}
          label={formatMessage({
            id: 'scrollToTopButton',
            defaultMessage: 'Nach oben',
          })}
        />
      </Box>
    </Fade>
  );
};
