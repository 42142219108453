import type { SxProps } from '@mui/material';

export const variationRowStyles: Record<
  'variationName' | 'indicator',
  Record<'default' | 'disabled', SxProps>
> = {
  variationName: {
    default: { flex: '1 1 100%' },
    disabled: { flex: '1 1 auto' },
  },
  indicator: {
    default: { width: '32px', height: '32px', flex: '0 0 32px' },
    disabled: {
      flex: '1 1 auto',
      textAlign: 'right',
      '& strong': {
        fontWeight: '600',
        whiteSpace: 'nowrap',
      },
    },
  },
};
