import DOMPurify from 'isomorphic-dompurify';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box } from '../Box';
import { HtmlTooltip } from './Tooltip.Html';
import { TooltipButton } from './Tooltip.Button';
import type { TooltipItemType } from './types';

/**
 * TooltipItem component - A customized HTML tooltip that displays content conditionally based on the device type.
 *
 * Note: Due to issues with Material-UI Tooltip on real mobile devices in version 5,
 * tooltips are only displayed in the click version wrapped with ClickAwayListener on mobile devices.
 * This setup allows for a consistent user experience across different platforms.
 * You can try out all variants here: https://mui.com/material-ui/react-tooltip/
 *
 * @param {TooltipItemType} props - The properties for the TooltipItem.
 * @param {string | React.ReactNode} props.content - The HTML content or React component to be displayed inside the tooltip.
 * @param {'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'} props.direction - The placement of the tooltip relative to the target element. Options include 'top-start', 'top-end', 'bottom-start', 'bottom-end'.
 * @returns A React element that renders a customized Tooltip based on the specified configuration.
 */
export const TooltipItem = ({ variant, content: htmlContent, direction }: TooltipItemType) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipIsOpen(false);
  };

  const handleTooltip = () => {
    setTooltipIsOpen(!tooltipIsOpen);
  };

  // generate unique identifier. example: Math.random() ==> 0.123456789, toString(36) ==> 0.4kfzyo1m2, substring(2, 9) ==> 4kfzyo1m2
  const tooltipId = `tooltip-${Math.random().toString(36).substring(2, 9)}`;

  return (
    <Box
      className="tooltip-cms"
      component="span"
      sx={{
        display: 'inline-flex',
      }}
    >
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box className="tooltip" component="span">
          <HtmlTooltip
            variant={variant}
            {...(direction && { placement: direction })}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltipIsOpen}
            title={
              <Box
                id={tooltipId}
                className="tooltip"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent || '') }}
                p={0}
              />
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <TooltipButton
              ariaLabelledBy={tooltipId}
              variant={variant}
              handleTooltip={handleTooltip}
            />
          </HtmlTooltip>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
