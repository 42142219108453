import type { ChangeEvent } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

import { Disadvantage32 } from '@packages/themes/icons';

import type { Control, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Radio } from '../Radio';

/** Based on MUI Radio: https://mui.com/material-ui/react-radio-button/ */
export const RadioGroup = <T extends FieldValues>({
  initialValue,
  handleChange,
  options,
  label,
  control,
  controllerName,
  error,
  errorMessage,
}: {
  initialValue: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  label?: string;
  control?: Control<T>;
  controllerName?: ControllerRenderProps<T>['name'];
  error?: boolean;
  errorMessage?: string;
}) => {
  if (control && controllerName) {
    return (
      <>
        <FormControl>
          {label && <FormLabel id="controlled-radio-buttons-group">{label}</FormLabel>}
          <Controller
            name={controllerName}
            control={control}
            render={({ field }) => (
              <MuiRadioGroup
                {...field}
                aria-labelledby="controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={field.value || ''}
                onChange={handleChange}
              >
                {options.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio key={option.value} color="default" error={error} />}
                    label={option.label}
                    key={`label-${option.value}`}
                    disabled={option.disabled}
                  />
                ))}
              </MuiRadioGroup>
            )}
          />
        </FormControl>
        {error && errorMessage && (
          <FormHelperText
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.1,
              color: 'error.main',
              paddingLeft: '2px', // set padding left to match ux design
            }}
          >
            <Disadvantage32 sx={{ width: '1rem', height: '1rem', color: 'error.main' }} />
            {errorMessage}
          </FormHelperText>
        )}
      </>
    );
  }
  return (
    <>
      <FormControl>
        {label && <FormLabel id="controlled-radio-buttons-group">{label}</FormLabel>}
        <MuiRadioGroup
          aria-labelledby="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={initialValue}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio key={option.value} color="default" error={error} />}
              label={option.label}
              key={`label-${option.value}`}
              disabled={option.disabled}
            />
          ))}
        </MuiRadioGroup>
      </FormControl>
      {error && errorMessage && (
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.1,
            color: 'error.main',
            paddingLeft: '2px', // set padding left to match ux design
          }}
        >
          <Disadvantage32 sx={{ width: '1rem', height: '1rem', color: 'error.main' }} />
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};

RadioGroup.displayName = 'RadioGroup';
