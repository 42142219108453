import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgRadioDisabledSelected24 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" sx={{
    ...sx
  }} {...other}><g fill="none" fillRule="evenodd" transform="translate(3 3)"><circle cx={9} cy={9} r={8.5} fill="#EEF3F7" stroke="#B1B4B7" /><circle cx={9} cy={9} r={4} fill="#B1B4B7" /></g></SvgIcon>;
};
const Memo = memo(SvgRadioDisabledSelected24);
export default Memo;