import { defineMessage, useIntl } from 'react-intl';
import { Service32 } from '@packages/themes/icons';
import { ErrorBoundary } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import { Suspense } from 'react';
import type { Locale } from '@packages/config/src/default';
import { Slot } from './Slot';
import { ServiceSlotMenuList } from './ServiceSlotMenuList';

const title = defineMessage({
  id: 'slot.service.label',
  defaultMessage: 'Service & Hilfe',
});

/** Service slot with list of helpers in popover */
export const ServiceSlot = () => {
  const { formatMessage, locale } = useIntl();
  const { headerLinks } = useConfig();

  return (
    <Slot
      useRouterLink={false}
      icon={<Service32 />}
      title={formatMessage(title)}
      href={headerLinks[locale as Locale]?.serviceLink}
    >
      <ErrorBoundary>
        <Suspense>
          <ServiceSlotMenuList />
        </Suspense>
      </ErrorBoundary>
    </Slot>
  );
};
