import { LinkButton, RadioGroup, Stack, Typography } from '@packages/shared';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useTracking } from '@packages/tracking';
import config from '@packages/config';
import { messages } from '../PromotionBanner.messages';
import { PromotionBannerTestIds } from '../PromotionBannerTestIds';
import type { AccordionPromotion } from '../types';
import { PromotionAccordionTemplate } from './PromotionAccordionTemplate';

export const PromotionAsChoiceLayer = ({
  activePromotion,
  selectedPromotion,
  setActiveCode,
}: {
  activePromotion: AccordionPromotion;
  selectedPromotion: AccordionPromotion;
  setActiveCode: (
    activeCode: string,
    textsCode: string,
    isPaybackPromotion: boolean,
  ) => Promise<void>;
}) => {
  const { formatMessage } = useIntl();
  const [choiceVariant, setChoiceVariant] = useState<string>('selected');
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatchGTMEvent = useTracking();

  return (
    <PromotionAccordionTemplate
      defaultExpanded
      isExpanded={isExpanded}
      toggleFunction={() => setIsExpanded(!isExpanded)}
      isPaybackPromotion={!!selectedPromotion.isPaybackPromotion}
      summaryContent={formatMessage(messages.choiceSummary)}
      detailscontent={
        <Stack alignItems={config.styleVariant === 'dark' ? 'left' : 'center'}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
            }}
            paragraph
          >
            {formatMessage(messages.choiceSelect, { br: <br /> })}
          </Typography>
          <RadioGroup
            data-testid={PromotionBannerTestIds.choice}
            initialValue={choiceVariant}
            handleChange={(event) => {
              setChoiceVariant(event.target.value);

              dispatchGTMEvent({
                event: 'trackPromoBanner',
                eventLabel: 'promo-banner',
                eventValue: `choice_layer_changed_${
                  event.target.value === 'active' ? activePromotion.name : selectedPromotion.name
                }`,
              });
            }}
            options={[
              {
                label: activePromotion.title,
                value: 'active',
              },
              {
                label: selectedPromotion.title,
                value: 'selected',
              },
            ]}
          />
          <LinkButton
            color="inherit"
            onClick={(event) => {
              event.preventDefault();
              const promotionToActivate =
                choiceVariant === 'active' ? activePromotion : selectedPromotion;
              setActiveCode(
                promotionToActivate.cartCode ?? '',
                promotionToActivate.textsCode,
                promotionToActivate.isPaybackPromotion,
              );
              dispatchGTMEvent({
                event: 'trackPromoBanner',
                eventLabel: 'promo-banner',
                eventValue: `choice_layer_changed_${promotionToActivate.name}`,
              });
            }}
            sx={{
              mt: 3.6,
              ...(config.styleVariant === 'dark' && {
                justifyContent: 'left',
              }),
            }}
          >
            {formatMessage(messages.choicePersist)}
          </LinkButton>
        </Stack>
      }
    />
  );
};
