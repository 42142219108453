'use client';

import {
  ConditionalWrapper,
  Box,
  Link,
  DynamicYield,
  useTrackCampaignOnLoad,
  Tooltip,
} from '@packages/shared';
import { useEffect } from 'react';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';
import type { UspLinkProps } from './types';

/**
 * UspLink component.
 *
 * @param {object} props - Component props.
 * @param {string} props.dynamicID - The dynamic ID.
 * @param {object} props.uspItem - The USP item data.
 *
 * @returns JSX.Element The rendered UspLink component.
 */
export const UspLink = ({ dynamicID, uspItem }: UspLinkProps) => {
  const {
    translatable_url: url,
    translatable_title: text,
    promotion_item: tooltipDataPromotion,
    content_snippet_bundled: tooltipDataContentSnippet,
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,
  } = uspItem;
  const { trackClick, trackDisplay } = useTrackCampaignOnLoad(uspItem, uspItem.translatable_url);

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);

  useEffect(() => {
    trackDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const child = () => (
    <ConditionalWrapper
      condition={!!(tooltipContent && !!tooltipActivation)}
      wrapper={(ch) => (
        <Tooltip variant="inline" content={tooltipContent} positioning={tooltipPositioning}>
          {ch}
        </Tooltip>
      )}
    >
      <Box
        sx={{
          lineHeight: '1.5rem',
        }}
        component="span"
      >
        {text}
      </Box>
    </ConditionalWrapper>
  );

  return (
    <Box>
      <DynamicYield dataDyId={dynamicID} />
      {url && (
        <Link
          href={url}
          noLinkStyle
          sx={{
            display: 'flex',
            pt: '0.4rem',
            pb: '0.4rem',
            typography: 'body1',
            fontSize: '0.875rem',
            textAlign: 'center',
          }}
          onClick={() => trackClick()}
        >
          {child()}
        </Link>
      )}
      {!url && child()}
    </Box>
  );
};
