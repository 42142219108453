import DOMPurify from 'isomorphic-dompurify';
import type { ReactNode } from 'react';
import type { SxProps } from '@mui/material';
import type { EfficiencyFlagProps } from '@packages/shared';
import { Box, MenuItem, Typography, FadedImage, Link, EfficiencyFlag } from '@packages/shared';
import type { Suggestion } from '../types';
import { useSuggest } from './SuggestionContext';

export interface SuggestionItemProps {
  /** Suggestion which item shows */
  suggestion: Suggestion;
  /** Callback when item is clicked */
  onClick?: () => void;
}

/** Wrapper component for Suggestion */
const SuggestionItemWrapper = ({
  suggestion,
  sx,
  children,
  onClick,
}: {
  suggestion: Suggestion;
  sx?: SxProps;
  children: ReactNode;
  onClick?: () => void;
}) => {
  const selectedItem = useSuggest();

  const { fullUri, value } = suggestion;

  return (
    <Link
      id={suggestion === selectedItem ? 'selected-item' : ''}
      href={fullUri || `/s/${value}`}
      underline="none"
      noLinkStyle
      onMouseDown={(e) => {
        // avoid onBlur before following link
        e.preventDefault();
      }}
      onClick={onClick}
    >
      <MenuItem sx={sx} selected={suggestion === selectedItem} data-testid="suggestion-item">
        {children}
      </MenuItem>
    </Link>
  );
};

export const SuggestionItem = ({ suggestion, onClick }: SuggestionItemProps) => (
  <SuggestionItemWrapper suggestion={suggestion} onClick={onClick}>
    <Typography
      variant="body2"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(suggestion.suggest),
      }}
    />
  </SuggestionItemWrapper>
);

export const SuggestionArticleItem = ({ suggestion, onClick }: SuggestionItemProps) => {
  const {
    value,
    suggest,
    imageUrl,
    price,
    energyEfficiencyLabel,
    energyEfficiencyLabelImgUrl,
    energyEfficiencyLabelImgName,
    tireEnergyEfficiencies,
  } = suggestion;

  return (
    <SuggestionItemWrapper
      suggestion={suggestion}
      sx={{ display: 'flex', alignItems: 'flex-start' }}
      onClick={onClick}
    >
      {imageUrl && (
        <FadedImage
          src={imageUrl}
          fill
          alt={value}
          sx={{
            flexShrink: 0,
            position: 'relative',
            width: 60,
            height: 90,
            mr: 2,
            objectFit: 'contain',
          }}
        />
      )}
      <Box>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(suggest) }}
        />
        {energyEfficiencyLabel && !energyEfficiencyLabelImgUrl && (
          <EfficiencyFlag
            type={
              energyEfficiencyLabel.includes('A_bis_G') ? 'energyEfficiency' : 'energyEfficiencyOld'
            }
            text={energyEfficiencyLabel.split('_').reverse()[1]}
            labelColor={
              energyEfficiencyLabel.split('_').reverse()[0] as EfficiencyFlagProps['labelColor']
            }
            sx={{ mt: 0.5 }}
          />
        )}
        {energyEfficiencyLabelImgUrl && !energyEfficiencyLabel && (
          /** we have to use the native image element because eek image flags can differ in their height
           and since next/image requires the height to be set and we are not able to check if it
           is an old or a new eek image we can only set the height to auto */
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={energyEfficiencyLabelImgUrl}
            width={45}
            height="auto"
            alt={energyEfficiencyLabelImgName}
          />
        )}
        <Typography variant="body2">{price}</Typography>
        {tireEnergyEfficiencies && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 0.5, gap: 1 }}>
            {tireEnergyEfficiencies.map(({ componentName: type, class: text }) => (
              <EfficiencyFlag key={type} type={type} text={text} />
            ))}
          </Box>
        )}
      </Box>
    </SuggestionItemWrapper>
  );
};
