import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { ColorTilesValuesFragmentFragmentDoc } from '@packages/gql/generated/shopping/ColorTilesValuesFragmentFragmentDoc';
import { Box, ColorButton, Link, Stack, Typography } from '@packages/shared';
import { useIsBot } from '@packages/tracking';
import { usePathname, useSearchParams } from 'next/navigation';
import { getVariationFormattedLink } from '../../helpers';
import type { SetSelectedVariation } from '../../types/types';
import { activeStyles, defaultStyles } from './styles';

/* GraphQL */ `
  fragment ColorTilesValuesFragment on ProductDimension {
    values {
      displayText
      image
      isSelected
      rgb
      sku
    }
  }
`;

export type ColorTilesDimensionProps = {
  /**
   * The GraphQL dimension data for the given product
   */
  dimensionData: FragmentType<typeof ColorTilesValuesFragmentFragmentDoc>;
  selectedVariation: string | null;
  onChange: SetSelectedVariation;
};

/**
 * ColorTilesDimension to choose a product color on Detailview
 * */
export const ColorTilesDimension = ({
  dimensionData,
  onChange,
  selectedVariation,
}: ColorTilesDimensionProps) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const isBot = useIsBot();
  const dimension = getFragmentData(ColorTilesValuesFragmentFragmentDoc, dimensionData);
  const selectedColor = dimension.values.find((value) => value.isSelected);

  return (
    <Box sx={{ mt: 0.5 }}>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {selectedColor ? selectedColor.displayText : ''}
      </Typography>
      <Stack direction="row" useFlexGap flexWrap="wrap" gap={1.5} sx={{ mt: 0.75 }}>
        {dimension.values.map(({ sku, image, rgb, displayText, isSelected }) => {
          const isActive = isSelected || false;
          return (
            <ColorButton
              onClick={() => onChange(sku)}
              key={`colorBtn${sku}`}
              minimal={false}
              image={image || undefined}
              color={rgb ? `#${rgb}` : ''}
              name={displayText || ''}
              active={isActive}
              isSelected={selectedVariation === sku}
              sx={isActive ? activeStyles : defaultStyles}
              LinkComponent={Link}
              href={isBot ? getVariationFormattedLink(sku, searchParams, pathname) : undefined}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
