import { Box, Typography } from '@packages/shared';
import { useEffect, type FC, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useInspiringSearchPlaceholders } from './useInspiringSearchPlaceholders';

const messages = defineMessages({
  prefix: { id: 'searchbar.animatedplaceholder.prefix', defaultMessage: 'Finde' },
});

const wait = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

export type SearchAnimatedPlaceholderProps = {
  /**
   * Duration each placeholder stays visible [milliseconds]
   *
   * @default 3500
   */
  textDuration?: number;
  /**
   * Duration of each half of the animation, both fade-out and fade-in [milliseconds]
   *
   * @default 350
   */
  animationDuration?: number;
};

/**
 * Animated Placeholder for search field promoting SearchBuddy
 * */
export const SearchAnimatedPlaceholder: FC<SearchAnimatedPlaceholderProps> = ({
  textDuration = 3500,
  animationDuration = 350,
}) => {
  const { formatMessage } = useIntl();
  const [selectedTextIndex, setSelectedTextIndex] = useState(0);
  const [animationState, setAnimationState] = useState<'hidden' | 'visible'>('visible');

  const placeholders = useInspiringSearchPlaceholders(5);

  useEffect(() => {
    const transitionToNext = async () => {
      setAnimationState('hidden');

      // wait for the out transition before actually changing the index
      await wait(animationDuration);

      setSelectedTextIndex((current) => (current + 1) % placeholders.length);
      setAnimationState('visible');
    };

    // show each placeholder for `textDuration` milliseconds, then transition to next one
    const interval = setInterval(() => {
      transitionToNext();
    }, textDuration);

    return () => clearInterval(interval);
  }, [animationDuration, placeholders.length, textDuration]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        maxWidth: '100%',
        zIndex: 1,
        pointerEvents: 'none',

        padding: 1.626,
        paddingBottom: 1.626,
        paddingLeft: 2.25,
        paddingRight: 7.75,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          position: 'relative',
          color: (theme) => theme.palette.text.darkTransparent,
        }}
      >
        {formatMessage(messages.prefix)}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          position: 'relative',
          left: `${animationState === 'visible' ? 3 : -2}px`,
          opacity: animationState === 'visible' ? 1 : 0,
          color: (theme) => theme.palette.text.darkTransparent,
          transition: `${animationDuration}ms`,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {formatMessage(placeholders[selectedTextIndex])}
      </Typography>
    </Box>
  );
};
