import dynamic from 'next/dynamic';

import { Skeleton } from '@packages/shared';

// TODO: use suspense pattern after nextjs issue is fixed: https://github.com/vercel/next.js/issues/36636#issuecomment-1143499522
export const DynamicSearchField = dynamic<{
  autofocus?: boolean;
  onSearch?: () => void;
}>(() => import('./SearchField'), {
  ssr: false,
  loading: () => <Skeleton variant="rectangular" width="100%" height={44} />,
  // suspense: true,
});
