import {
  FormHelperText,
  Radio as MuiRadio,
  useTheme,
  type RadioProps as MuiRadioProps,
} from '@mui/material';

import {
  Radio24,
  RadioDisabled24,
  RadioSelected24,
  RadioDisabledSelected24,
  Disadvantage32,
} from '@packages/themes/icons';

export type RadioProps = MuiRadioProps & {
  error?: boolean;
  errorMessage?: string;
};

export const Radio = (props: RadioProps) => {
  const { disabled, error, errorMessage } = props;
  const theme = useTheme();

  return (
    <>
      <MuiRadio
        icon={
          disabled ? (
            <RadioDisabled24 />
          ) : (
            <Radio24
              stroke={error ? theme.palette.error.main : '#4E5051'}
              strokeWidth={error ? 2 : 1}
            />
          )
        }
        checkedIcon={disabled ? <RadioDisabledSelected24 /> : <RadioSelected24 />}
        disableFocusRipple
        {...props}
        data-testid="mui-radio"
      />
      {error && errorMessage && (
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.1,
            color: 'error.main',
            paddingLeft: '13px', // set padding left to match ux design
          }}
        >
          <Disadvantage32 sx={{ width: '1rem', height: '1rem', color: 'error.main' }} />
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
