import type { TemplateConfig } from './types';

const templateNames = {
  gallery: '$PDV_gallery$',
  dvDesk: '$format_dv_desk_132$',
  dvMobile: '$format_dv_mob_075$',
  variationSelect: '$dv_variation_36$',
  zoomDesk: '$format_dv_zoom_desk_35$',
  zoomMobile: '$format_dv_zoom_mob_10$',
};

export const templates: TemplateConfig = {
  compact: {
    desktop: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 88,
        height: 88,
      },
    },
    tablet: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 72,
        height: 72,
      },
    },
    mobile: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 72,
        height: 72,
      },
    },
  },
  gallery: {
    desktop: {
      templateName: templateNames.dvDesk,
      imageDimensions: {
        width: 658,
        height: 658,
      },
    },
    tablet: {
      templateName: templateNames.dvMobile,
      imageDimensions: {
        width: 640,
        height: 640,
      },
    },
    mobile: {
      templateName: templateNames.dvMobile,
      imageDimensions: {
        width: 640,
        height: 640,
      },
    },
  },
  thumbnail: {
    desktop: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 96,
        height: 96,
      },
    },
    tablet: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 60,
        height: 60,
      },
    },
    mobile: {
      templateName: templateNames.gallery,
      imageDimensions: {
        width: 60,
        height: 60,
      },
    },
  },
  variationSelect: {
    desktop: {
      templateName: templateNames.variationSelect,
      imageDimensions: {
        width: 36,
        height: 36,
      },
    },
    tablet: {
      templateName: templateNames.variationSelect,
      imageDimensions: {
        width: 36,
        height: 36,
      },
    },
    mobile: {
      templateName: templateNames.variationSelect,
      imageDimensions: {
        width: 36,
        height: 36,
      },
    },
  },
  zoom: {
    desktop: {
      templateName: templateNames.zoomDesk,
      imageDimensions: {
        width: 2048,
        height: 2048,
      },
    },
    tablet: {
      templateName: templateNames.zoomMobile,
      imageDimensions: {
        width: 1000,
        height: 1000,
      },
    },
    mobile: {
      templateName: templateNames.zoomMobile,
      imageDimensions: {
        width: 1000,
        height: 1000,
      },
    },
  },
};
