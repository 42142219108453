import type { Language, Config } from '@packages/config';

/**
 * Replaces the current language in a root-relative url with another language.
 *
 * @param url A root-relative URL (e.g. `/de/test` or `/`, but not `test` or `foo/bar`).
 * @param language any valid language code for the given config
 * @param config Partial i18n config
 * @returns The href with the new language prefixed, unless it is the default language, in which case the prefix is removed.
 */
export const localizeUrl = (
  url: string,
  language: Language,
  config: { i18n: Pick<Config['i18n'], 'languages' | 'defaultLocale'> },
) => {
  const availableLanguages = config.i18n.languages;

  if (!availableLanguages.includes(language)) {
    throw new Error(
      `unsupported language: '${language}', please make sure it is a valid language code for the given config. Available languages: ${availableLanguages.join(', ')}`,
    );
  }

  const regex = new RegExp(`^/?(${availableLanguages.join('|')})(/|$)`);

  const defaultLanguage = config.i18n.defaultLocale.substring(0, 2);

  if (regex.test(url)) {
    return url.replace(regex, language === defaultLanguage ? '/' : `/${language}$2`);
  }

  if (url.startsWith('/')) {
    // default language has no prefix, keep the url as is
    if (language === defaultLanguage) return url;

    // frontpage (like other cms pages) must not have a trailing slash
    if (url === '/') return `/${language}`;

    return `/${language}${url}`;
  }

  throw new Error(`unsupported href: '${url}', please make sure it is a rooted path`);
};
