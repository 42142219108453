import {
  Typography,
  Link,
  Card,
  CardHeader,
  CardContent,
  Box,
  LoginButton,
  ErrorBoundary,
  Button,
  Anchor,
} from '@packages/shared';
import { useLoginState } from '@packages/utilities';
import type { ReactNode, FC } from 'react';
import { Suspense, lazy } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Service32, Wishlist32, Newsletter32, Adressbook32 } from '@packages/themes/icons';
import { AccountOverviewFallback } from '../../AccountOverview/AccountOverviewFallback';

type AccountSlotProps = {
  onLogin: () => void;
};

const messages = defineMessages({
  ADDITIONAL_HEADER: {
    id: 'slot.account.additionalfunctions.header',
    defaultMessage: 'Weitere Funktionen',
  },
  SERVICE_HELP: {
    id: 'slot.account.additionalfunctions.servicehelp',
    defaultMessage: 'Service & Hilfe',
  },
  WISHLIST: {
    id: 'slot.account.additionalfunctions.wishlist',
    defaultMessage: 'Merkzettel',
  },
  NEWSLETTER: {
    id: 'slot.account.additionalfunctions.newsletter',
    defaultMessage: 'Newsletter',
  },
  ADDRESSBOOK: {
    id: 'slot.account.additionalfunctions.addressbook',
    defaultMessage: 'Adressbuch',
  },
  LINK_SERVICE_HELP: {
    id: 'slot.account.additionalfunctions.servicehelp.link',
    defaultMessage: '/service-hilfe/',
  },
  LINK_WISHLIST: {
    id: 'slot.account.additionalfunctions.wishlist.link',
    defaultMessage: '/merkzettel/',
  },
  LINK_NEWSLETTER: {
    id: 'slot.account.additionalfunctions.newsletter.link',
    defaultMessage: '/service-hilfe/newsletter/',
  },
  LINK_ADDRESSBOOK: {
    id: 'slot.account.additionalfunctions.addressbook.link',
    defaultMessage: '/mein-konto/meine-lieferanschriften',
  },
});

const LazyOverview = lazy(
  () => import(/* webpackChunkName: 'AccountOverview' */ '../../AccountOverview/AccountOverview'),
);

const CardAction: FC<{
  icon: ReactNode;
  text: string;
  href: string;
  external?: boolean;
}> = ({ icon, text, href, external = false }) =>
  external ? (
    <Anchor
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
        alignItems: 'center',
        py: 1,
        px: 2,
        color: 'text.dark',
        flex: 1,
        cursor: 'pointer',
      }}
      href={href}
    >
      <Box
        sx={{
          height: '1.5rem',
          width: '1.5rem',
          mr: 2,

          '& > *': {
            height: '1.5rem',
            width: '1.5rem',
          },
        }}
      >
        {icon}
      </Box>
      <Typography variant="body2">{text}</Typography>
    </Anchor>
  ) : (
    <Link
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
        alignItems: 'center',
        py: 1,
        px: 2,
        color: 'text.dark',
        flex: 1,
        cursor: 'pointer',
      }}
      href={href}
    >
      <Box
        sx={{
          height: '1.5rem',
          width: '1.5rem',
          mr: 2,

          '& > *': {
            height: '1.5rem',
            width: '1.5rem',
          },
        }}
      >
        {icon}
      </Box>
      <Typography variant="body2">{text}</Typography>
    </Link>
  );

export const AdditionalFunctions: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: 'grey.light',
      }}
    >
      <Card>
        <CardHeader
          sx={{
            py: 1,
            px: 2,
            borderBottom: '1px solid',
            borderBottomColor: 'grey.main',
            fontFamily: 'default',
            fontSize: '1rem',
          }}
          disableTypography
          title={formatMessage(messages.ADDITIONAL_HEADER)}
        />
        <CardContent>
          <CardAction
            icon={<Service32 />}
            text={formatMessage(messages.SERVICE_HELP)}
            href={formatMessage(messages.LINK_SERVICE_HELP)}
          />
          {/* External flag needed until wishlist has been migrated to nextjs. */}
          <CardAction
            external
            icon={<Wishlist32 />}
            text={formatMessage(messages.WISHLIST)}
            href={formatMessage(messages.LINK_WISHLIST)}
          />
          <CardAction
            icon={<Newsletter32 />}
            text={formatMessage(messages.NEWSLETTER)}
            href={formatMessage(messages.LINK_NEWSLETTER)}
          />
          <CardAction
            icon={<Adressbook32 />}
            text={formatMessage(messages.ADDRESSBOOK)}
            href={formatMessage(messages.LINK_ADDRESSBOOK)}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export const AccountSlot: FC<AccountSlotProps> = ({ onLogin }) => {
  const { loggedIn } = useLoginState();
  const { formatMessage } = useIntl();

  return (
    <>
      {loggedIn ? (
        <Box
          sx={{ px: 1, py: 2, backgroundColor: 'grey.light' }}
          data-testid="account-slot-loggedin"
        >
          <ErrorBoundary
            fallback={(reset) => (
              <Box>
                <Typography>
                  {formatMessage({
                    id: 'Navigation.Account.customerData.error',
                    defaultMessage: 'Nutzerdaten konnten nicht abgerufen werden',
                  })}
                </Typography>
                <Button onClick={reset}>Wiederholen</Button>
              </Box>
            )}
          >
            <Suspense fallback={<AccountOverviewFallback />}>
              <LazyOverview />
            </Suspense>
          </ErrorBoundary>
        </Box>
      ) : (
        <Box sx={{ px: 3, py: 1 }}>
          <LoginButton fullWidth onClick={onLogin} />
        </Box>
      )}
      <AdditionalFunctions />
    </>
  );
};
