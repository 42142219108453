import type { CheckoutPage } from '@packages/gql/generated/shopping/graphql';

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

type CookieCategory = 'C0001' | 'C0003' | 'C0002' | 'C0004' | 'C0005';

export type GeoCountryCode = string | undefined;
export type Flags = 'de' | 'ch' | 'fr' | 'at' | 'it' | 'nl';
export type Language = 'de' | 'fr' | 'en' | 'nl';
export type Locale = 'de-DE' | 'fr-CH' | 'de-CH' | 'de-AT' | 'nl-NL';

type PreRenderAtBuldTimeVariants = 'type' | 'cmsPage';

export const localizedPathnameCacheKey = 'localizedPathname';

export enum Environment {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const environment =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? Environment.STAGING : Environment.PRODUCTION;

export type Config = {
  host: {
    name: string;
    domain: string;
  };
  i18n: {
    defaultLocale: Locale;
    languages: Language[];
    locales: Locale[];
  };
  clientId: string;
  tenantId: string;
  styleVariant: 'default' | 'dark';
  company: {
    name: string;
    seoTitleName: string;
  };
  captcha: {
    recaptchaSessionToken: string;
  };
  assets: {
    cmsImage: {
      imageQuality: number;
      imageOptimizationEnabled: boolean;
      uriTransformSource: RegExp;
      uriTransformEndpoint: string;
    };
  };
  apiEndpoints: {
    search: string;
    searchFilter: string;
    searchSeo: string;
    suggest: string;
    historyGet: string;
    historyApiNew: string;
    historyDelete: string;
    historyDeleteAll: string;
    historyDeleteAllNew: string;
    historyJoinWithFi: string;
    customer: string;
  };
  dynamicYieldEnabled: boolean;
  hermesShopFinder: HermesShopFinderConfig;
  product: ProductConfig;
  installmentCalculator: InstallmentCalculatorConfig;
  header: HeaderConfig;
  search: SearchConfig;
  order: OrderConfig;
  staticContent: StaticContentConfig;
  footer: FooterConfig;
  banner: BannerConfig;
  nlSheetTexts: NlSheetTextsConfig;
  serviceLayer: ServiceLayerConfig;
  seoContent: SeoContentConfig;
  survey: SurveyConfig;
  sovendus: {
    enabled: boolean;
  };
  chatBot: ChatBotConfig;
  promotionBanner: PromotionBannerConfig;
  voucher: VoucherConfig;
  recommendations: ReccomendationsConfig;
  searchResultUserFeedback: FeedbackConfig;
  shoppingApp: {
    pathsToRenderChildrenOnly: string[];
  };
  navigation: {
    firstLevel: {
      color: string;
      backgroundColor?: string;
      border: string;
    };
    secondLevel?: {
      color: string;
      backgroundColor?: string;
      border: string;
    };
    loadSliced: boolean;
    firstLevelFont?: string;
    firstLevelFontSize?: string;
  };
  forms: {
    apiAgnitasUrl: {
      enabled: boolean;
      environmentRelated: boolean;
      values: {
        url: string;
        useDOI: boolean;
        sendAdditionalSubscribe: boolean;
      };
    };
    contact: {
      selectOptionsSubject: { label: string; value: string }[];
    };
    newsletterUnsubscribe: {
      fields: CmsFormsFieldConfig[];
      useDedicatedSubmitButtonTitleFor?: string[];
    };
    newsletterSubscribe: {
      [key in Language]: { unsubscribeLink: string; voucherLink: string };
    };
    rateProtection: {
      [key in Language]: {
        termsIpid: string;
        termsVag: string;
        termsAvb: string;
        termsVvinfo: string;
      };
    };
    newsletterSheet: NewsletterSheetConfig;
    validation: {
      rules: {
        customerId: {
          regex: RegExp;
          errorMessage: {
            id: string;
            defaultMessage: string;
          };
        };
      };
    };
  };
  enableCustomerNrLogin: boolean;
  headerSlots: {
    localeSwitcher: boolean;
    payback: boolean;
    warehouse: boolean;
    joe: boolean;
    serviceApi?: string;
  };
  headerLinks: {
    [key in Locale]?: {
      serviceLink: string;
      myAccount: string;
    };
  };
  appBanner: {
    enabled: boolean;
    appUrl: string;
  };
  tracking: TrackingConfig;
  testPanel: TestPanelConfiguration;
  externalScript?: {
    kuecheCo?: {
      key: string;
    };
  };
  video: {
    video_youtube: { url: string; provider: string; name: string };
  };
  headerInformation: {
    [key in Language]?: {
      description: string;
      title: string;
      canonical: string;
      robots: string;
    };
  };
  customerRatings: {
    api: {
      feedback: string;
      filter: string;
      new: string;
      verifiedRatingsLayerURL: string;
      submitRatingURL: string;
    };
    termsId: string;
    pagingCount: number;
  };
  customerCentricFeedback: {
    enabled: boolean;
    tenantId: string;
    surveyIdStorefront: string;
    surveyIdSearch: string;
    surveyIdDetailview: string;
  };
  dynamicYieldTracking: {
    key: string;
    endpoint: string;
    endpointClientSide: string;
  };
  geoCountryLayer: {
    enabled: boolean;
    flag?: Flags;
    img: {
      path: string;
      alt: string;
      title: string;
    };
    alternatives?: Array<AlternativesShops>;
  };
  redirectOverlay: {
    enabled: boolean;
    redirectParam: string;
    redirectLp: {
      de: string;
      fr?: string;
    };
  };
  lounge: {
    icon?: (lang: string) => string;
  };
  bookings: {
    overview: {
      hasExplicitMaturities: boolean;
      showOpenPayment: 'none' | 'minimal-payment' | 'installments' | 'sum-and-open';
    };
  };
  internalPages: InternalPages;
};

export enum LoginStatus {
  LoggedIn,
  NotLoggedIn,
}

export type InternalPageMetadata = {
  title?: string;
};

export const enum InternalPageContainer {
  Overview = 1,
  PersonalData,
  Addressbook,
  Bookings,
  ShipmentInfos,
  CallcenterLogin,
  Lounge,
  PasswordForget,
  CallcenterADFSLoginResponse,
}

export interface InternalPage {
  paths: { [key in Language]: string };
  additionalPaths?: Array<string>;
  nativeAppCategories: string[];
  isPrefix?: boolean;
  container: InternalPageContainer;
  requiredLoginStatus: LoginStatus;
  metaData: { title?: string };
  trackingTemplate: string;
}

export type InternalPages = {
  // overview: InternalPage;
  // personaldata: InternalPage;
  // addressbook: InternalPage;
  bookings: InternalPage;
  shipmentinfos: InternalPage;
  // lounge: InternalPage;
  // callcenterLogin: InternalPage;
  // callcenterADFSLoginResponse: InternalPage;
  // passwordForget: InternalPage;
};

export namespace Bookings {
  export namespace Invoice {
    export const deUrl = '/mein-konto/meine-rechnungen';
    export const frUrl = '/fr/mon-compte/mes-factures';
    export const catalogCategory = 'MyAccountMob-meine-rechnungen';
  }
  export namespace Payment {
    export const deUrl = '/mein-konto/meine-zahlungen';
    export const frUrl = '/fr/mon-compte/mes-paiements';
    export const catalogCategory = 'MyAccountMob-meine-zahlungen';
  }
  export namespace Reimbursement {
    export const deUrl = '/mein-konto/meine-ruecksendungen';
    export const frUrl = '/fr/mon-compte/mes-retours';
    export const catalogCategory = 'MyAccountMob-meine-gutschriften';
  }
}

export type SchemaOrgOrganisation = {
  [key in Locale]: {
    addressCountry: string;
    addressRegion: string;
    addressLocality: string;
    alternateName: string[];
    areaServed: string;
    contactEmail: string;
    contactUrl: string;
    description: string;
    email: string;
    hoursAvailable: string[];
    legalName: string;
    logo: string;
    name: string;
    postalCode: string;
    privacyNoticePath: string;
    potentialAction: {
      target: string;
    };
    productSupported: string;
    sameAs: string[];
    streetAddress: string;
    telephone: string;
    url: string;
  };
};

export type AlternativesShops = {
  flag?: Flags;
  shops?: Array<AlternativesShopsItem>;
};

export type AlternativesShopsItem = {
  link?: string;
  title?: string;
  closeLayer?: boolean;
};

interface TrackingConfig {
  soastaId: string;
  tagmanagerId: string;
  tagmanagerDomain?: string;
  /** Full script url. Overwrites tagmanagerId and tagmanagerDomain config */
  tagmanagerSrc?: string;
  googleSiteVerificationID?: string;
  msvalidateID?: string;
  schemaOrgOrganisation: SchemaOrgOrganisation;
  pinterestRichPinVerificationID?: string;
}

interface TestPanelConfiguration {
  apps?: TestPanelApp[];
  backends?: TestPanelApp[];
  systems?: TestPanelSystem[];
}

export interface TestPanelApp {
  appLabel: string;
  appName: string;
  defaultBranch: string;
  projectId: string;
  featureCookieName: string;
  hasPathQuerySyntax?: boolean;
}

export interface TestPanelSystem {
  appLabel: string;
  appName: string;
  connector: 'apiKeyManager';
  systems: Record<string, string>;
  featureCookieName?: string;
  defaultBranch?: string;
}

export interface ExternalService {
  apiKey?: string;
  apiUrl?: string;
  consentGroup?: CookieCategory;
  containerName?: string;
  customerId?: string;
  debugEnabled?: boolean;
  enabled?: boolean;
  moreInformationLink?: string;
  scriptUrl?: string;
}
export interface ExternalServiceCliplister {
  consentGroup: CookieCategory;
  enabled: boolean;
  scriptUrl: string;
  customerId: string;
}

export interface ExternalServiceMazing {
  enabled: boolean;
}

export interface ExternalServiceAplus {
  enabled: boolean;
}

interface PrerenderAtBuildTime {
  params: {
    [key in PreRenderAtBuldTimeVariants]?: string[];
  };
  locale: string;
}

export type PersonalizationSegmentSource = 'legacy' | 'baur' | 'unito';

type AdServerDisabledConfig = {
  mode: 'disabled';
  mkzBlacklist: number[];
};

type AdServerContainerOnlyConfig = {
  mode: 'containerOnly';
  mkzBlacklist: number[];
};

type AdServerDoubleClickConfig = {
  mode: 'doubleClick';
  adServerClientId: string;
  adServerCustomerName: string;
  mkzBlacklist: number[];
};

type AdServerConfig =
  | AdServerDisabledConfig
  | AdServerContainerOnlyConfig
  | AdServerDoubleClickConfig;

type SearchConfig = {
  personalizationSegmentSource: PersonalizationSegmentSource;
  adServer: AdServerConfig;
  hideTelekomIcon: boolean;
  isInspiringSearchEnabled: boolean;
};

type ProductConfig = {
  // TODO: remove after abc test
  abcTestRecos: {
    enabled: boolean;
  };
  aplus: ExternalServiceAplus;
  cliplister: ExternalServiceCliplister;

  flixmedia: {
    distributorId?: number;
    enabled: boolean;
  };
  mazing: ExternalServiceMazing;
  reviews: {
    hintLayerId: string;
  };
  taxHint: {
    hideAdditionalDeliveryInfo: boolean;
    modalContent: {
      S: {
        link: string;
      };
      L: {
        link: string;
      };
      DEFAULT: {
        link: string;
      };
    };
  };
  voucherPrice: {
    enabled: boolean;
  };
  usePrintPromo: boolean;
  hasKIM: boolean;
  hasSwogo: boolean;
  hasCarbonHint: boolean;
  price: {
    hasSalePrimaryColor: boolean;
  };
  shortageVersion: 'Version1' | 'Version2' | null;
};

type StaticContentConfig = {
  fetchSettings: {
    timeout: number;
  };
  apiEndpoints: {
    jsonApi: string;
    bucket: string;
    webappJson: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

type HeaderConfig = {
  hasNewNavigation: boolean;
  hasSmallerFontSize: boolean;
  desktopNavigationMode: 'click' | 'hover';
};

type FooterConfig = {
  fetchSettings: {
    timeout: number;
  };
  apiEndpoints: {
    jsonApi: string;
    bucket: string;
    deDupingInterval: number;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

type ReccomendationsConfig = {
  apiEndpoint: string;
};

type FeedbackConfig = {
  appUrl?: string;
  enabled: boolean;
};

type BannerConfig = {
  apiEndpoints: {
    bucket: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

type NlSheetTextsConfig = {
  apiEndpoints: {
    bucket: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

type ServiceLayerConfig = {
  apiEndpoints: {
    api: string;
    bucket: string;
    bucketPages: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

type SurveyConfig = {
  apiEndpoints: {
    bucket: string;
  };
};

type HermesShopFinderConfig = {
  url: string;
  rParam: string;
};

type NewsletterSheetConfig = {
  enabled: boolean;
};

export type ChatBotConfig = {
  enabled: boolean;
  key: string;
  includePaths: string[];
  excludePaths: string[];
  visibility: {
    main: boolean;
    button: boolean;
    notifications: boolean;
  };
};

type SeoContentConfig = {
  apiEndpoints: {
    bucket: string;
  };
};

type PromotionBannerConfig = {
  apiEndpoints: {
    jsonApi: string;
    bucketPromotion: string;
    bucketEmptyPromotions: string;
    bucketContentSnippet: string;
  };
  abTest?: string;
};

export type CmsFormsFieldOptionsConfig = {
  id: string;
  value: string;
  messageKey: string;
};

export type CmsFormsFieldConfig = {
  id: string;
  type?: string;
  defaultValue?: string;
  options?: CmsFormsFieldOptionsConfig[];
  formType?: 'input' | 'radio' | 'select' | 'text' | 'email' | 'number' | 'password' | 'date';
  messageKeyPlaceholder?: string;
  additionalForm?: CmsFormsFieldConfig;
  showOnlyWhenWhatSelected?: string;
};

type VoucherConfig = {
  apiEndpoints: {
    api: string;
    bucket: string;
  };
};

type InstallmentCalculatorConfig = {
  hasLegalHints: boolean;
  hasLegalHintsImage: boolean;
  hasInfoCallToAction: boolean;
  InstallmentCalculatorCallToActionLayout:
    | { layout: 'Layout1' }
    | { layout: 'Layout2' }
    | {
        layout: 'Layout3';
        openCalculatorIn: { type: 'Modal' } | { type: 'Card'; calculatorID: string };
      }
    | null;
  hasSpecialCreditColors: boolean;
};

export type LocalizedPath = {
  [key in Locale]?: string;
} & {
  default: string;
};

const tenantId = 'default';

type OrderSealNameType =
  | 'trustedShops'
  | 'ehi'
  | 'staatswappen'
  | 'handelsverband'
  | 'ecommerce'
  | 'oeonline'
  | 'dqs';

export type OrderSealsConfig = {
  [key in OrderSealNameType]: {
    enabled: boolean;
    url: string;
  };
};

export type OrderAssetsConfig = {
  seals: {
    getSealImage: ({
      language,
      sealName,
    }: {
      language: string;
      sealName: OrderSealNameType;
    }) => string;
  };
  premium: {
    getPremiumImage: ({ language, device }: { language: string; device: string }) => string;
    getPremiumIcon?: ({ language }: { language: string }) => string;
  };
  gifs: {
    success: {
      url: string;
    };
  };
};

type OrderPaybackType = {
  enabled: boolean;
  link?: string;
  cardNumberLength?: number;
  cardNumberHintUrl?: string;
};

export type LocalizedCheckoutPath = LocalizedPath & {
  // we don't have all page Links given to us yet. once we do this type cast can be stricter.
  page?: CheckoutPage;
};

export type OrderConfig = {
  basketUrls: {
    basket: LocalizedCheckoutPath;
    login: LocalizedCheckoutPath;
    delivery: LocalizedCheckoutPath;
    changeDelivery: LocalizedCheckoutPath;
    payment: LocalizedCheckoutPath;
    changePayment: LocalizedCheckoutPath;
    paymentprotection: LocalizedCheckoutPath;
    paymentprotectionupdate: LocalizedCheckoutPath;
    delayedPayment: LocalizedCheckoutPath;
    premium: LocalizedCheckoutPath;
    checkandorder: LocalizedCheckoutPath;
    orderconfirmation: LocalizedCheckoutPath;
    noOrder: LocalizedCheckoutPath;
    afterPayment: LocalizedCheckoutPath;
  };
  externalUrls: {
    agb: LocalizedPath;
    joe: LocalizedPath;
  };
  layers: {
    servicelayer: LocalizedPath;
  };
  joe: {
    enabled: boolean;
  };
  seals: OrderSealsConfig;
  assets: OrderAssetsConfig;
  hasCo2Compensation: boolean;
  hasFlexikontoAdvantageClub: boolean;
  payback: OrderPaybackType;
};

const config: Config = {
  host: { name: 'Default Host', domain: '/' },
  i18n: {
    defaultLocale: 'de-DE',
    languages: ['de'],
    locales: ['de-DE'],
  },
  clientId: 'default',
  tenantId,
  styleVariant: 'default',
  company: {
    name: 'default',
    seoTitleName: 'default',
  },
  captcha: {
    recaptchaSessionToken: '',
  },
  assets: {
    cmsImage: {
      imageQuality: 75,
      imageOptimizationEnabled: true,
      uriTransformSource: /^cloud-storage-(.*?):\/\//,
      uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
    },
  },
  apiEndpoints: {
    search: '/api/search/searchv2',
    searchFilter: '/api/search/filter',
    searchSeo: '/api/search/seo',
    suggest: '/api/search/suggest',
    historyGet: '/api/search/searchhistory/',
    historyApiNew: '/api/search/searchhistory/v1',
    historyDelete: '/api/search/searchhistory/delete',
    historyDeleteAll: '/api/search/searchhistory/deleteall',
    historyDeleteAllNew: '/api/search/searchhistory/v1/all',
    historyJoinWithFi: '/api/search/searchhistory/v1/join-with-fi',
    customer: '/api/customer/v1',
  },
  dynamicYieldEnabled: true,
  hermesShopFinder: {
    url: 'https://paketshop.myhermes.de/int/hpsf.html',
    rParam: 'be22763e-f7e9-4',
  },
  product: {
    abcTestRecos: {
      enabled: false,
    },
    aplus: {
      enabled: true,
    },
    cliplister: {
      customerId: '',
      consentGroup: 'C0004',
      enabled: true,
      scriptUrl:
        'https://mycliplister.com/merge?cliplister=1.6&clviewer=1.23&videostage=1.18&innercontrols=1.7&clickablevideo=1.4&playbutton=1.1&previewimage=1.4&bufferingspinner=1.5&nextbutton=1.2&autoplaynext=1.1',
    },
    flixmedia: {
      enabled: false,
      distributorId: undefined,
    },
    hasSwogo: true,
    reviews: {
      hintLayerId: 'hint_kdbe_verifiziert',
    },
    mazing: {
      enabled: true,
    },
    shortageVersion: null,
    taxHint: {
      hideAdditionalDeliveryInfo: false,
      modalContent: {
        DEFAULT: {
          link: 'delivery_default',
        },
        L: {
          link: 'delivery_spedition-layer-L',
        },
        S: {
          link: 'delivery_spedition-layer-S',
        },
      },
    },
    voucherPrice: {
      enabled: true,
    },
    price: {
      hasSalePrimaryColor: true,
    },
    hasKIM: true,
    usePrintPromo: true,
    hasCarbonHint: true,
  },
  installmentCalculator: {
    hasLegalHints: false,
    hasLegalHintsImage: false,
    hasInfoCallToAction: true,
    InstallmentCalculatorCallToActionLayout: { layout: 'Layout1' },
    hasSpecialCreditColors: false,
  },
  header: {
    hasNewNavigation: false,
    hasSmallerFontSize: false,
    desktopNavigationMode: 'click',
  },
  search: {
    personalizationSegmentSource: 'unito',
    adServer: {
      mode: 'disabled',
      mkzBlacklist: [957],
    },
    hideTelekomIcon: false,
    isInspiringSearchEnabled: false,
  },
  order: {
    basketUrls: {
      basket: { default: '/warenkorb', 'fr-CH': '/fr/warenkorb' },
      login: {
        default: '/kasse/registrieren',
        'fr-CH': '/fr/kasse/registrieren',
        page: 'LoginPage',
      },
      delivery: {
        default: '/kasse/lieferservice',
        'fr-CH': '/fr/kasse/lieferservice',
      },
      changeDelivery: {
        default: '/kasse/lieferservice-aendern',
        'fr-CH': '/fr/kasse/lieferservice-aendern',
      },
      payment: { default: '/kasse/zahlung', 'fr-CH': '/fr/kasse/zahlung' },
      changePayment: {
        default: '/kasse/zahlung-aendern',
        'fr-CH': '/fr/kasse/zahlung-aendern',
      },
      paymentprotection: {
        default: '/kasse/ratenschutz',
        'fr-CH': '/fr/kasse/ratenschutz',
      },
      paymentprotectionupdate: {
        default: '/kasse/ratenschutz-aktualisierung',
        'fr-CH': '/fr/kasse/ratenschutz-aktualisierung',
      },
      delayedPayment: {
        default: '/kasse/zahlpause',
        'fr-CH': '/fr/kasse/zahlpause',
      },
      premium: { default: '/kasse/premium', 'fr-CH': '/fr/kasse/premium' },
      checkandorder: { default: '/kasse', 'fr-CH': '/fr/kasse', page: 'CheckAndOrder' },
      orderconfirmation: {
        default: '/kasse/auftrag',
        'fr-CH': '/fr/kasse/auftrag',
      },
      noOrder: { default: '/kasse/hinweis', 'fr-CH': '/fr/kasse/hinweis' },
      afterPayment: {
        default: '/kasse/bezahlt/',
        'fr-CH': '/fr/kasse/bezahlt/',
      },
    },
    externalUrls: {
      agb: {
        default: '/service-hilfe/ueber-uns/agb/',
        'fr-CH': '/fr/aide-service/qui-sommes-nous/cgv',
      },
      joe: {
        default: 'https://www.joe-club.at/registrieren/',
      },
    },
    seals: {
      ehi: {
        enabled: false,
        url: '',
      },
      trustedShops: {
        enabled: false,
        url: '',
      },
      staatswappen: {
        enabled: false,
        url: '',
      },
      handelsverband: {
        enabled: false,
        url: '',
      },
      ecommerce: {
        enabled: false,
        url: '',
      },
      oeonline: {
        enabled: false,
        url: '',
      },
      dqs: {
        enabled: false,
        url: '',
      },
    },
    joe: {
      enabled: false,
    },
    assets: {
      seals: {
        getSealImage: ({ language, sealName }) => {
          switch (sealName) {
            case 'trustedShops':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/trustedshops.jpg`;
            case 'ehi':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/siegel_ehi.png`;
            case 'staatswappen':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/staatswappen.png`;
            case 'handelsverband':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/handelsverband.jpg`;
            case 'ecommerce':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/ecommerce.jpg`;
            case 'oeonline':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/oeonline.png`;
            case 'dqs':
              return `https://bilder.baur.de/i/empiriecom/io/${language}/ISO-14001-D.png`;
            default:
              return '';
          }
        },
      },
      premium: {
        getPremiumImage: ({ device }) =>
          `https://bilder.universal.at/empiriecom/io/universalat/de/BS3_CheckOut_${device}.jpg`,
      },
      gifs: {
        success: {
          url: 'https://bilder.baur.de/i/empiriecom/io/de/thank_you_1_transparent.gif',
        },
      },
    },
    layers: {
      servicelayer: {
        default: '',
      },
    },
    hasCo2Compensation: true,
    hasFlexikontoAdvantageClub: false,
    payback: {
      enabled: false,
    },
  },
  navigation: {
    firstLevel: {
      color: 'text.dark',
      border: 'primary.main',
    },
    loadSliced: false,
  },
  enableCustomerNrLogin: false,
  headerSlots: {
    localeSwitcher: false,
    payback: false,
    warehouse: false,
    joe: false,
  },
  headerLinks: {
    'de-DE': {
      serviceLink: '/service-hilfe',
      myAccount: '/mein-konto/uebersicht',
    },
    'de-AT': {
      serviceLink: '/service-hilfe',
      myAccount: '/mein-konto/uebersicht',
    },
    'de-CH': {
      serviceLink: '/service-hilfe',
      myAccount: '/mein-konto/uebersicht',
    },
    'fr-CH': {
      serviceLink: '/fr/aide-service',
      myAccount: '/fr/mon-compte/apercu',
    },
    'nl-NL': {
      serviceLink: '/klantenservice',
      myAccount: '/mein-konto/uebersicht',
    },
  },
  staticContent: {
    fetchSettings: {
      timeout: 5000,
    },
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
      bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  footer: {
    fetchSettings: {
      timeout: 5000,
    },
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      deDupingInterval: 3000,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  forms: {
    apiAgnitasUrl: {
      enabled: true,
      environmentRelated: true,
      values: {
        url: '/api/inspire/agnitas/v0',
        useDOI: true,
        sendAdditionalSubscribe: false,
      },
    },
    contact: {
      selectOptionsSubject: [
        { label: 'forms.type.subject.option.order', value: 'order' },
        { label: 'forms.type.subject.option.datasecurity', value: 'datasecurity' },
        { label: 'forms.type.subject.option.web', value: 'web' },
        { label: 'forms.type.subject.option.account', value: 'account' },
        { label: 'forms.type.subject.option.personalData', value: 'personalData' },
      ],
    },
    newsletterUnsubscribe: {
      useDedicatedSubmitButtonTitleFor: undefined,
      fields: [
        {
          id: 'newsletterrrrrr',
          formType: 'radio',
          options: [
            {
              id: 'frq-daily',
              value: 'frequency|0',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
            },
            {
              id: 'frq-weekly',
              value: 'frequency|1',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
            },
            {
              id: 'frq-bi-weekly',
              value: 'frequency|2',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.biweekly',
            },
            {
              id: 'frq-monthly',
              value: 'frequency|3',
              messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.monthly',
            },
            {
              id: 'unsubscribe',
              value: 'unsubscribe|99',
              messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
            },
            {
              id: 'pause',
              value: 'pause|-100',
              messageKey: 'forms.newsletter.unsubscribe.type.pause',
            },
          ],
          additionalForm: {
            id: 'pause',
            formType: 'select',
            showOnlyWhenWhatSelected: 'pause',
            messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
            options: [
              {
                id: 'pause-7',
                value: '7',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
              },
              {
                id: 'pause-14',
                value: '14',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
              },
              {
                id: 'pause-21',
                value: '21',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
              },
              {
                id: 'pause-30',
                value: '30',
                messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
              },
            ],
          },
        },
      ],
    },
    newsletterSubscribe: {
      de: {
        unsubscribeLink: 'https://default/de/link/to/unsubscribe',
        voucherLink: 'https://default/de/link/to/voucher',
      },
      fr: {
        unsubscribeLink: 'https://default/fr/link/to/unsubscribe',
        voucherLink: 'https://default/fr/link/to/voucher',
      },
      en: {
        unsubscribeLink: 'https://default/en/link/to/unsubscribe',
        voucherLink: 'https://default/en/link/to/voucher',
      },
      nl: {
        unsubscribeLink: 'https://default/en/link/to/unsubscribe',
        voucherLink: 'https://default/en/link/to/voucher',
      },
    },
    rateProtection: {
      de: {
        termsIpid: 'https://default/de/link/to/ipid',
        termsVag: 'https://default/de/link/to/vag',
        termsAvb: 'https://default/de/link/to/avb',
        termsVvinfo: 'https://default/de/link/to/vvinfo',
      },
      fr: {
        termsIpid: 'https://default/fr/link/to/ipid',
        termsVag: 'https://default/fr/link/to/vag',
        termsAvb: 'https://default/fr/link/to/avb',
        termsVvinfo: 'https://default/fr/link/to/vvinfo',
      },
      en: {
        termsIpid: 'https://default/en/link/to/ipid',
        termsVag: 'https://default/en/link/to/vag',
        termsAvb: 'https://default/en/link/to/avb',
        termsVvinfo: 'https://default/en/link/to/vvinfo',
      },
      nl: {
        termsIpid: 'https://default/en/link/to/ipid',
        termsVag: 'https://default/en/link/to/vag',
        termsAvb: 'https://default/en/link/to/avb',
        termsVvinfo: 'https://default/en/link/to/vvinfo',
      },
    },
    newsletterSheet: {
      enabled: true,
    },
    validation: {
      rules: {
        customerId: {
          regex: /^[0-9]*$/,
          errorMessage: {
            id: 'forms.error.numbersonly',
            defaultMessage: 'Nur Zahlen sind erlaubt',
          },
        },
      },
    },
  },
  voucher: {
    apiEndpoints: {
      api: '/api/voucher',
      bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
    },
  },
  banner: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  nlSheetTexts: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  serviceLayer: {
    apiEndpoints: {
      api: '/api/servicelayer',
      bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
    ssg: {
      revalidateInterval: 15,
      prerenderAtBuildTime: [],
    },
  },
  seoContent: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
    },
  },
  survey: {
    apiEndpoints: {
      bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
    },
  },
  chatBot: {
    enabled: false,
    key: '',
    includePaths: ['/'],
    excludePaths: [],
    visibility: {
      main: true,
      button: true,
      notifications: false,
    },
  },
  shoppingApp: {
    pathsToRenderChildrenOnly: ['/servicelayer/'],
  },
  sovendus: {
    enabled: true,
  },
  promotionBanner: {
    apiEndpoints: {
      jsonApi: `/<locale>/<page>?_format=webapp_json&flat`,
      bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
      bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
      bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/<language>/${tenantId}/<identifier>.json`,
    },
  },
  video: {
    video_youtube: {
      url: 'https://www.youtube-nocookie.com/embed/<id>?autoplay=<autoplay>&playsinline=1&rel=0',
      provider: 'YouTube',
      name: 'YouTube',
    },
  },
  recommendations: {
    apiEndpoint: '/api/inform/graphql',
  },
  searchResultUserFeedback: {
    appUrl:
      'https://sw01.rogsurvey.de/html/survey.htm?n=empiriecom/Suchefeedback_<buttonType>&ref=<sessionId>&ref=<searchQuery>',
    enabled: true,
  },
  appBanner: { enabled: true, appUrl: '' },
  tracking: {
    soastaId: '',
    tagmanagerId: '',
    pinterestRichPinVerificationID: '',
    tagmanagerDomain: undefined, // explicitly undefined here for default values to work in TrackingProvider
    tagmanagerSrc: undefined, // explicitly undefined here for overwrite check in TrackingProvider
    schemaOrgOrganisation: {
      'de-DE': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'de-AT': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'de-CH': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'fr-CH': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
      'nl-NL': {
        addressCountry: 'DE',
        addressRegion: 'BY',
        addressLocality: 'Burgkunstadt',
        alternateName: [],
        areaServed: 'DE',
        contactEmail: 'service@empirie.dev',
        contactUrl: 'https://www.empirie.dev/kontakt',
        description: 'Ein Emma Demo-Shop',
        email: 'service@empirie.dev',
        hoursAvailable: [],
        legalName: 'empiriecom GmbH & Co. KG',
        logo: `/assets/logos/${tenantId}/logo.svg`,
        name: 'EMMA Shop',
        postalCode: '96224',
        privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
        productSupported: '',
        sameAs: [],
        streetAddress: 'Bahnhofstraße 10',
        telephone: '',
        url: 'https://www.empirie.dev',
        potentialAction: {
          target: 'https://empirie.dev/s/',
        },
      },
    },
  },
  testPanel: {
    apps: [
      {
        appLabel: 'Shopping',
        appName: 'app-shopping',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-shopping',
      },
      {
        appLabel: 'Order',
        appName: 'app-order',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-order',
      },
      {
        appLabel: 'Legacy Header',
        appName: 'app-header',
        defaultBranch: 'master',
        projectId: '35259303',
        featureCookieName: 'feature-app-header',
      },
    ],
    backends: [
      {
        appLabel: 'Customer',
        appName: 'backend-mybuy-customer',
        defaultBranch: 'master',
        projectId: '19850631',
        featureCookieName: 'feature-backend-customer',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Wishlist',
        appName: 'backend-mybuy-wishlist',
        defaultBranch: 'master',
        projectId: '20841174',
        featureCookieName: 'feature-backend-wishlist',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Checkout',
        appName: 'backend-mybuy-checkout',
        defaultBranch: 'master',
        projectId: '21918200',
        featureCookieName: 'feature-backend-checkout',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Paylink',
        appName: 'backend-mybuy-paylink',
        defaultBranch: 'master',
        projectId: '24424921',
        featureCookieName: 'feature-backend-paylink',
        hasPathQuerySyntax: true,
      },
      {
        appLabel: 'Joe',
        appName: 'cloud-service-order-joe',
        defaultBranch: 'master',
        projectId: '21571033',
        featureCookieName: 'feature-joe-service',
      },
    ],
  },
  headerInformation: {
    de: {
      description: 'default description',
      title: 'default title',
      canonical: 'https://default.de',
      robots: 'noodp,index,follow',
    },
    fr: {
      description: 'default description',
      title: 'default title',
      canonical: 'https://default.fr',
      robots: 'noodp,index,follow',
    },
  },
  customerRatings: {
    api: {
      feedback: '/api/inform/rating/all/get',
      filter: '/api/inform/rating/feedback',
      new: '/api/inform/rating/new/get',
      verifiedRatingsLayerURL: '/fragment/inspire/servicelayer/<language>/<layerid>',
      submitRatingURL: '/api/inform/rating/new/store',
    },
    termsId: 'hint_nutzungsbedingungen_kdbeweformular',
    pagingCount: 10,
  },
  customerCentricFeedback: {
    enabled: true,
    tenantId: '1900',
    surveyIdStorefront: '3__e4c7114417e509881f280c5adb7f4c93',
    surveyIdSearch: '3__f371392e3fb65c250deca2350c28124d',
    surveyIdDetailview: '3__56263b79205164c26026b71a8a2c4423',
  },
  dynamicYieldTracking: {
    key: '',
    endpoint: 'https://dy-api.eu/v2/collect/user/event',
    endpointClientSide: '/api/dynamicyield/<dyType>/<dyId>/<dySession>/<hashedEmail>',
  },
  geoCountryLayer: {
    enabled: false,
    img: {
      path: 'https://storage.googleapis.com/emp-common-libs/assets/geo-country-layer/<tenant>/360x360.jpg',
      alt: '',
      title: '',
    },
  },
  redirectOverlay: {
    enabled: false,
    redirectParam: '',
    redirectLp: {
      de: '',
    },
  },
  lounge: {},
  bookings: {
    overview: {
      hasExplicitMaturities: false,
      showOpenPayment: 'minimal-payment',
    },
  },
  internalPages: {
    shipmentinfos: {
      nativeAppCategories: ['MyAccountMob-meine-bestellungen-lieferungen'],
      container: InternalPageContainer.ShipmentInfos,
      paths: {
        de: '/mein-konto/meine-bestellungen-und-lieferungen',
        fr: '/fr/mon-compte/mes-commandes-et-livraisons',
        en: '/',
        nl: '/',
      },
      isPrefix: true,
      requiredLoginStatus: LoginStatus.LoggedIn,
      metaData: {
        title: 'meta.title.shipmentinfos',
      },
      trackingTemplate: 'shipmentInfos',
    },
    bookings: {
      nativeAppCategories: [
        'MyAccountMob-meine-buchungen',
        Bookings.Invoice.catalogCategory,
        Bookings.Payment.catalogCategory,
        Bookings.Reimbursement.catalogCategory,
      ],
      container: InternalPageContainer.Bookings,
      paths: {
        de: '/mein-konto/meine-buchungen',
        fr: '/fr/mon-compte/mes-reservations',
        en: '/',
        nl: '/',
      },
      additionalPaths: [
        Bookings.Invoice.deUrl,
        Bookings.Invoice.frUrl,
        Bookings.Payment.deUrl,
        Bookings.Payment.frUrl,
        Bookings.Reimbursement.deUrl,
        Bookings.Reimbursement.frUrl,
      ],
      requiredLoginStatus: LoginStatus.LoggedIn,
      metaData: {},
      trackingTemplate: 'booking',
    },
  },
};

export const validLanguages = ['de', 'en', 'fr', 'it', 'es', 'nl', 'pl', 'pt', 'da', 'sv'];

export const getFullLocaleFromConfig = (
  localeOrLanguage: string | undefined,
  configObject: Config,
): Locale => {
  const result = localeOrLanguage
    ? configObject.i18n.locales.find((locale) => locale.startsWith(localeOrLanguage))
    : undefined;
  return result || configObject.i18n.defaultLocale;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const customArrayMerge = (_target: any[], source: any[], _options: any) => source;

/** DON'T USE. Import from '@packages/config' for tenant specific version!
 *  This function is a dummy for the default tenant.
 */
export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
